import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import Header from './Header';
import Map from './Map';
import VariableSelector from './VariableSelector';
import AddressAutocomplete from './AddressAutocomplete';
import Legend from './Legend';  // Import the Legend component

const DashboardPage = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const searchParams = new URLSearchParams(location.search);
  const [zipCode, setZipCode] = useState(searchParams.get('zipcode'));
  const [selectedVariable, setSelectedVariable] = useState(null);
  const [cbsa, setCbsa] = useState(null);
  const [metricData, setMetricData] = useState(null);
  const [varLabels, setVarLabels] = useState(null);
  const [legendData, setLegendData] = useState(null);  // New state for legend data
  const [isSidebarCollapsed, setIsSidebarCollapsed] = useState(false);


  useEffect(() => {
    if (zipCode) {
      fetch(`/api/zcta/${zipCode}/cbsa`)
        .then(response => response.json())
        .then(data => {
          if (data.cbsa) {
            setCbsa(data.cbsa);
          }
        })
        .catch(error => console.error('Error fetching CBSA:', error));
    }
  }, [zipCode]);

  useEffect(() => {
    fetch('/api/vars')
      .then(response => response.json())
      .then(data => {
        setVarLabels(data);
        if (!selectedVariable && data.length > 0) {
          setSelectedVariable(data[0]);
        }
      })
      .catch(error => console.error('Error fetching var labels:', error));
  }, []);

  useEffect(() => {
    if (cbsa && selectedVariable) {
      console.log('Fetching metric data for:', selectedVariable.variable);
      // Get the primary variable and all tooltip variables
      const tooltipVariables = selectedVariable.tooltipVariables.map(tv => tv.tooltip);
      const columnsToFetch = [selectedVariable.variable, ...tooltipVariables];
      const columns = Array.from(new Set(columnsToFetch)).join(',');

      fetch(`/api/metrics/${cbsa}?columns=${columns}`)
        .then(response => response.json())
        .then(data => {
          console.log('Metric data fetched, processing...');
          const processedData = data.map(zcta => {
            const processedZcta = { ...zcta };
            // Format the primary variable
            // processedZcta[selectedVariable.variable] = formatMetricValue(zcta[selectedVariable.variable], selectedVariable.format);
            processedZcta[selectedVariable.variable] = zcta[selectedVariable.variable]
            // Create tooltips object
            processedZcta.tooltips = selectedVariable.tooltipVariables.map(tv => ({
              label: tv.tooltip_label,
              value: formatMetricValue(zcta[tv.tooltip], tv.tooltip_format)
            }));
            return processedZcta;
          });
          console.log('Processed metric data, sample:', processedData[0]);
          setMetricData(processedData);
        })
        .catch(error => console.error('Error fetching metric data:', error));
    }
  }, [cbsa, selectedVariable]);

  const formatMetricValue = (value, formatting) => {
    if (value == null) return 'N/A';
    
    switch (formatting) {
      case 'dollar':
        return new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD', maximumFractionDigits: 0 }).format(value);
      case 'percent':
        return new Intl.NumberFormat('en-US', { style: 'percent', minimumFractionDigits: 1, maximumFractionDigits: 1 }).format(value);
      case 'non_dollar':
      default:
        return new Intl.NumberFormat('en-US').format(value);
    }
  };

  const handleLegendDataUpdate = (data) => {
    setLegendData(data);
  };

  const handleVariableSelect = (variable) => {
    console.log('Variable selected:', variable.variable);
    setSelectedVariable(variable);
  };

  const handleZipCodeChange = (newZipCode) => {
    setZipCode(newZipCode);
    navigate(`/dashboard?zipcode=${newZipCode}`, { replace: true });
  };

  const toggleSidebar = () => {
    setIsSidebarCollapsed(!isSidebarCollapsed);
  };

  return (
    <div className="relative min-h-screen overflow-hidden">
      {/* Map Container */}
      <div className="absolute inset-0">
        <Map 
          zipCode={zipCode} 
          cbsa={cbsa} 
          metricData={metricData} 
          selectedVariable={selectedVariable} 
          varLabels={varLabels}
          onLegendDataUpdate={handleLegendDataUpdate}
        />
      </div>
  
      {/* Sidebar */}
      <div 
        className={`absolute top-0 left-0 h-full bg-white shadow-lg z-20 transition-all duration-300 ${
          isSidebarCollapsed ? 'w-12' : 'w-80'
        }`}
      >
        <div className="p-4 border-b border-gray-200">
          {/* Sidebar header */}
          <div className="flex items-center justify-between mb-4">
            {!isSidebarCollapsed && (
              <h1 className="text-2xl font-bold">prop:metrics</h1>
            )}
            <button 
              onClick={toggleSidebar}
              className="text-gray-500 hover:text-gray-700 focus:outline-none ml-auto"
            >
              {isSidebarCollapsed ? (
                <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M13 5l7 7-7 7M5 5l7 7-7-7" />
                </svg>
              ) : (
                <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M11 19l-7-7 7-7m8 14l-7-7 7-7" />
                </svg>
              )}
            </button>
          </div>
  
          {/* Collapsing AddressAutocomplete */}
          {!isSidebarCollapsed && (
            <AddressAutocomplete 
              onSelectAddress={handleZipCodeChange} 
              className="mb-2" 
              showLabel={false}
            />
          )}
        </div>
  
        {/* Variable Selector */}
        {!isSidebarCollapsed && (
          <div className="h-full overflow-auto">
            <VariableSelector onSelectVariable={handleVariableSelect} initialVariable={selectedVariable} />
          </div>
        )}
      </div>
  
      {/* Legend Component */}
      {legendData && (
        <div className="absolute bottom-4 right-4 z-10">
          <Legend
            lowValue={legendData.lowValue}
            highValue={legendData.highValue}
            flipColors={legendData.flipColors}
            formatting={legendData.formatting}
            variableLabel={legendData.variableLabel}
          />
        </div>
      )}
    </div>
  );
};

export default DashboardPage;
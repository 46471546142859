import React from 'react';
import img_1 from '../../images/hp/by_size.png';
import img_2 from '../../images/hp/zip_code_insights.png';
import img_3 from '../../images/hp/investor_metrics.png';
import img_4 from '../../images/hp/demographics.png';

const InsightPane = ({ title, imageUrl, altText, description }) => (
    <div className="bg-gray-800 p-4 flex flex-col">
      <div className="flex-grow">
        <img src={imageUrl} alt={altText} className="w-full h-64 object-cover mb-4 rounded-lg"/>
      </div>
      <div className="text-center">
        <div className="text-lg font-semibold mb-2">{title}</div>
        <p className="text-sm">{description}</p>
      </div>
    </div>
  );
  
const SampleInsightsSection = () => {
    const insights = [
      {
        title: "Listing Price by # of bedrooms",
        imageUrl: img_1,
        altText: "Chart showing listing prices",
        description: "Compare average listing prices across different bedroom counts."
      },
      {
        title: "Zip Code Level Insights",
        imageUrl: img_2,
        altText: "Map with zip code data",
        description: "Detailed real estate data broken down by zip code."
      },
      {
        title: "Investor Metrics",
        imageUrl: img_3,
        altText: "Investment performance chart",
        description: "Key metrics for real estate investors including ROI and cap rates."
      },
      {
        title: "Demographic Changes",
        imageUrl: img_4,
        altText: "Population demographics chart",
        description: "Track population shifts and demographic trends in your area."
      }
    ];
  
    return (
      <section className="bg-black text-white py-12">
        <div className="container mx-auto px-4 flex flex-col items-center">
          <h2 className="text-3xl font-bold mb-8 text-center">Sample Insights</h2>
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-4 max-w-8xl">
            {insights.map((insight, index) => (
              <InsightPane key={index} {...insight} />
            ))}
          </div>
        </div>
      </section>
    );
};

export default SampleInsightsSection;

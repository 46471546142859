import React from 'react';
import { Link } from 'react-router-dom';


const Header = () => (
  <header className="bg-white shadow-md">
    <div className="container mx-auto px-4 py-4 flex justify-between items-center">
      <div className="text-2xl font-bold">prop:metrics</div>
      <nav className="space-x-4">
        <Link to="/" className="text-gray-700">Home</Link>
        <Link to="/dashboard" className="text-gray-700">Dashboard</Link>
        {/* <Link to="/maps" className="text-gray-700">Maps</Link>
        <Link to="/graphs" className="text-gray-700">Graphs</Link>
        <Link to="/reports" className="text-gray-700">Reports</Link>
        <Link to="/schools" className="text-gray-700">Schools</Link>
        <Link to="/about" className="text-gray-700">About</Link>
        <Link to="/faq" className="text-gray-700">FAQ</Link>
        <Link to="/pricing" className="text-gray-700">Pricing</Link>
        <Link to="/signup" className="text-red-500">Sign Up</Link>
        <Link to="/login" className="text-red-500">Login</Link> */}
      </nav>
    </div>
  </header>
);

export default Header;
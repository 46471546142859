import React, { useEffect, useRef } from 'react';
import mapboxgl from 'mapbox-gl';
import MapboxGeocoder from '@mapbox/mapbox-gl-geocoder';
import '@mapbox/mapbox-gl-geocoder/dist/mapbox-gl-geocoder.css';

const AddressAutocomplete = ({ onSelectAddress, className = '', showLabel = true }) => {
  const geocoderContainerRef = useRef(null);

  useEffect(() => {
    const geocoder = new MapboxGeocoder({
      accessToken: mapboxgl.accessToken,
      types: 'postcode',
      country: 'US',
      placeholder: 'Enter zip code in the US',
      bbox: [-125.0, 24.396308, -66.93457, 49.384358],  // Bounding box for continental US
      filter: function(item) {
        // Ensure the result is in the United States
        return item.context.some(context => context.id.startsWith('country.') && context.short_code === 'us');
      }
    });

    geocoder.addTo(geocoderContainerRef.current);

    geocoder.on('result', (e) => {
      const selectedFeature = e.result;
      let zipCode;

      if (selectedFeature.place_type[0] === 'postcode') {
        zipCode = selectedFeature.text;
      } else if (selectedFeature.context) {
        zipCode = selectedFeature.context.find(c => c.id.startsWith('postcode'))?.text;
      }

      if (zipCode) {
        onSelectAddress(zipCode);
      } else {
        console.error('No zip code found for the selected location');
      }
    });

    return () => {
      geocoder.onRemove();
    };
  }, [onSelectAddress]);

  return (
    <div className={className}>
      <div ref={geocoderContainerRef} className="geocoder-container w-full" />
      {showLabel}
    </div>
  );
};

export default AddressAutocomplete;
import React, { useEffect, useRef, useState, useCallback } from 'react';
import mapboxgl from 'mapbox-gl';
import 'mapbox-gl/dist/mapbox-gl.css';
import chroma from 'chroma-js';

mapboxgl.accessToken = process.env.REACT_APP_MAPBOX_TOKEN;

const Map = ({ zipCode, cbsa, metricData, selectedVariable, varLabels, onLegendDataUpdate }) => {
  const mapContainer = useRef(null);
  const map = useRef(null);
  const [zctaGeometries, setZctaGeometries] = useState(null);
  const popup = useRef(null);
  const [mapReady, setMapReady] = useState(false);
  const dataToRender = useRef(null);
  const hoveredZctaId = useRef(null);

  const initializeMap = useCallback(() => {
    if (map.current) return; // Initialize map only once

    map.current = new mapboxgl.Map({
      container: mapContainer.current,
      style: 'mapbox://styles/mapbox/standard',
      projection: 'mercator',
      center: [-98.5795, 39.8283],
      zoom: 4,
      trackResize: false, //maybe useful for silencing some messages
      fadeDuration: 0, //maybe useful for silencing some messages
      attributionControl: false //maybe useful for silencing some messages 
    });

    map.current.addControl(new mapboxgl.NavigationControl(), 'top-right');

    popup.current = new mapboxgl.Popup({
      closeButton: false,
      closeOnClick: false
    });

    map.current.on('load', () => {
      map.current.addSource('zctas', {
        type: 'geojson',
        data: {
          type: 'FeatureCollection',
          features: []
        }
      });

      map.current.addLayer({
        id: 'zcta-fills',
        type: 'fill',
        source: 'zctas',
        paint: {
          'fill-color': ['get', 'color'],
          'fill-opacity': 0.5
        }
      });

      map.current.addLayer({
        id: 'zcta-borders',
        type: 'line',
        source: 'zctas',
        paint: {
          'line-color': [
            'case',
            ['boolean', ['feature-state', 'hover'], false],
            '#000000',
            '#627BC1'
          ],
          'line-width': [
            'case',
            ['boolean', ['feature-state', 'hover'], false],
            4,
            2
          ]
        }
      });

      map.current.addLayer({
        id: 'zcta-labels',
        type: 'symbol',
        source: 'zctas',
        layout: {
          'text-field': [
            'format',
            ['get', 'zcta'], { 'font-scale': 1.1 },
            '\n',
            ['get', 'formattedMetricValue'], { 'font-scale': 0.9 }
          ],
          'text-anchor': 'center',
          'text-size': [
            'interpolate',
            ['linear'],
            ['zoom'],
            9, 0,
            10, 9,
            12, 15,
            14, 17
          ],
          'text-allow-overlap': false,
          'text-ignore-placement': false,
          'visibility': 'visible'
        },
        paint: {
          'text-color': '#000000',
          'text-halo-color': '#FFFFFF',
          'text-halo-width': 1,
          'text-opacity': [
            'interpolate',
            ['linear'],
            ['zoom'],
            9, 0,
            10, 1
          ]
        }
      });

      map.current.on('mousemove', 'zcta-fills', (e) => {
        if (e.features.length > 0) {
          const feature = e.features[0];
          map.current.getCanvas().style.cursor = 'pointer';

          if (hoveredZctaId.current !== null) {
            map.current.setFeatureState(
              { source: 'zctas', id: hoveredZctaId.current },
              { hover: false }
            );
          }
          hoveredZctaId.current = feature.id;
          map.current.setFeatureState(
            { source: 'zctas', id: hoveredZctaId.current },
            { hover: true }
          );

          // Populate and show tooltip
          const tooltipContent = createTooltipContent(feature.properties);
          popup.current
            .setLngLat(e.lngLat)
            .setHTML(tooltipContent)
            .addTo(map.current);
        }
      });

      map.current.on('mouseleave', 'zcta-fills', () => {
        map.current.getCanvas().style.cursor = '';
        if (hoveredZctaId.current !== null) {
          map.current.setFeatureState(
            { source: 'zctas', id: hoveredZctaId.current },
            { hover: false }
          );
        }
        hoveredZctaId.current = null;
        popup.current.remove();
      });

      setMapReady(true);
    });
  }, []);

  useEffect(() => {
    initializeMap();
  }, [initializeMap]);

  useEffect(() => {
    if (!cbsa || !map.current) return;

    fetch(`/api/zcta/${cbsa}`)
      .then(response => response.json())
      .then(geojson => {
        setZctaGeometries(geojson);
        
        if (zipCode) {
          const initialZcta = geojson.features.find(feature => feature.properties.zcta === zipCode);
          if (initialZcta) {
            const bounds = new mapboxgl.LngLatBounds();
            initialZcta.geometry.coordinates[0].forEach(coord => bounds.extend(coord));
            map.current.fitBounds(bounds, { padding: 50, maxZoom: 12 });
          }
        }
      })
      .catch(error => console.error('Error fetching ZCTA data:', error));
  }, [cbsa, zipCode]);

  useEffect(() => {
    if (!zctaGeometries || !metricData || !selectedVariable || !varLabels) return;

    const varLabel = varLabels.find(label => label.variable === selectedVariable.variable);

    const features = zctaGeometries.features.map(feature => {
      const zcta = feature.properties.zcta;
      const zctaData = metricData.find(d => d.zcta === zcta);
      const metricValue = zctaData?.[selectedVariable.variable];
      const formatting = varLabel ? varLabel.format : 'non_dollar';
      const formattedMetricValue = formatMetricValue(metricValue, formatting);

      return {
        ...feature,
        id: feature.properties.zcta,
        properties: {
          ...feature.properties,
          ...zctaData,
          formattedMetricValue,
          color: getColor(metricValue, selectedVariable.variable, metricData, varLabel?.flip_colors, varLabel?.format)
        }
      };
    });

    dataToRender.current = {
      type: 'FeatureCollection',
      features
    };

    if (mapReady) {
      updateMapSource();
    }
  }, [zctaGeometries, metricData, selectedVariable, varLabels, mapReady]);

  const updateMapSource = useCallback(() => {
    if (map.current && map.current.getSource('zctas') && dataToRender.current) {
      map.current.getSource('zctas').setData(dataToRender.current);
    }
  }, []);

  useEffect(() => {
    if (mapReady) {
      updateMapSource();
    }
  }, [mapReady, updateMapSource]);

  const formatMetricValue = (value, formatting) => {
    if (value == null) return '';
    
    switch (formatting) {
      case 'dollar':
        return new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD', maximumFractionDigits: 0 }).format(value);
      case 'percent':
        return new Intl.NumberFormat('en-US', { style: 'percent', minimumFractionDigits: 1, maximumFractionDigits: 1 }).format(value);
      case 'non_dollar':
      default:
        return new Intl.NumberFormat('en-US').format(value);
    }
  };

  const getQuantile = (arr, q) => {
    const sorted = arr.slice().sort((a, b) => a - b);
    const pos = (sorted.length - 1) * q;
    const base = Math.floor(pos);
    const rest = pos - base;
    if (sorted[base + 1] !== undefined) {
      return sorted[base] + rest * (sorted[base + 1] - sorted[base]);
    } else {
      return sorted[base];
    }
  };

  const getColor = (value, variableName, data, flipColors, formatting) => {
    // Check for null, undefined, or 'N/A' values
    if (value === null || value === undefined || value === 'N/A') {
      return '#CCCCCC';  // Return a neutral grey color for N/A values
    }

    const values = data.map(d => d[variableName]).filter(v => v != null && v !== 'N/A');    
    let scale;
    let lowValue, midValue, highValue;

    if (formatting === 'percent') {
      lowValue = Math.max(-1, getQuantile(values, 0.01));
      midValue = 0;
      highValue = Math.min(1, getQuantile(values, 0.995));
    } else {
      lowValue = getQuantile(values, 0.01);
      highValue = getQuantile(values, 0.99);
      midValue = getQuantile(values, 0.5); // median
    }

    if (flipColors === 1) {
      scale = chroma.scale(['#08519c', '#f7fbff', '#ef3b2c']);
    } else {
      scale = chroma.scale(['#ef3b2c', '#f7fbff', '#08519c']);
    }

    // Update legend data using the callback
    onLegendDataUpdate({
      lowValue,
      highValue,
      flipColors,
      formatting,
      variableLabel: selectedVariable.label
    });
    
    const clampedValue = Math.max(lowValue, Math.min(highValue, value));
    return scale.domain([lowValue, midValue, highValue])(clampedValue).hex();
  };

  const createTooltipContent = (properties) => {
    if (!properties.tooltips) {
      return `<strong>ZCTA: ${properties.zcta}</strong><br/>No data available`;
    }
  
    let tooltips;
    try {
      tooltips = typeof properties.tooltips === 'string' ? JSON.parse(properties.tooltips) : properties.tooltips;
    } catch (error) {
      console.error('Error parsing tooltips:', error);
      return `<strong>ZCTA: ${properties.zcta}</strong><br/>Error displaying data`;
    }
  
    let content = `<strong>ZCTA: ${properties.zcta}</strong><br/>`;
    tooltips.forEach(tooltip => {
      content += `${tooltip.label}: ${tooltip.value}<br/>`;
    });
  
    return content;
  };

  return <div ref={mapContainer} className="w-full h-full" />;
};

export default Map;
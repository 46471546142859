import React from 'react';
import { useNavigate } from 'react-router-dom';
import Header from './Header';
import AddressAutocomplete from './AddressAutocomplete';
import headerImage from '../images/header-image.jpg';
import allMetricsImage from '../images/hp/all_metrics.png';
import SampleInsightsSection from './subcomponents/hp_insights';

const HomePage = () => {
  const navigate = useNavigate();

  const handleSelectAddress = (zipCode) => {
    navigate(`/dashboard?zipcode=${zipCode}`);
  };

  return (
    <div>
      <Header />
      <main>
        <section className="relative">
          <img src={headerImage} alt="Aerial view of a residential area" className="w-full h-[600px] object-cover"/>
          <div className="absolute inset-0 bg-black bg-opacity-50 flex flex-col justify-center items-center text-center text-white">
            <h1 className="text-4xl font-bold">Real Estate Data in Seconds</h1>
            <div className="mt-4 w-120">
              <AddressAutocomplete onSelectAddress={handleSelectAddress} />
            </div>
          </div>
        </section>

        <SampleInsightsSection />

        {/* Add more sections here as needed */}

        <section className="bg-gray-100 py-12">
          <div className="container mx-auto px-4 flex flex-col md:flex-row items-center">
            <img src={allMetricsImage} alt="Map showing real estate insights" className="md:w-1/2 mb-4 md:mb-0"/>
            <div className="md:w-1/2 md:pl-8">
              <h2 className="text-3xl font-bold mb-4">Prop:Metrics has every key real estate metric</h2>
              <p className="mb-4">Track home price trends, rental rates, investor metrics, and other key data so you can make an informed and more objective real estate investment decision.</p>
              <div className="flex space-x-4">
                <button className="bg-red-500 text-white px-4 py-2" onClick={() => navigate('/dashboard')}>See Insights</button>
                <button className="bg-gray-700 text-white px-4 py-2">Show More Posts</button>
              </div>
            </div>
          </div>
        </section>
      </main>
    </div>
  );
};

export default HomePage;
import React, { useState, useEffect, useCallback, useMemo } from 'react';

const CustomTooltip = ({ content, children }) => {
  const [isVisible, setIsVisible] = useState(false);

  return (
    <div 
      className="relative inline-block"
      onMouseEnter={() => setIsVisible(true)}
      onMouseLeave={() => setIsVisible(false)}
    >
      {children}
      {isVisible && (
        <div className="absolute z-10 p-2 text-sm text-white bg-gray-800 rounded-lg shadow-lg right-0 mt-1 w-48">
          {content}
        </div>
      )}
    </div>
  );
};

const VariableSelector = ({ onSelectVariable, initialVariable }) => {
  const [categories, setCategories] = useState([]);
  const [selectedVariable, setSelectedVariable] = useState(null);
  const [openCategory, setOpenCategory] = useState(null);
  const [variablesData, setVariablesData] = useState([]);
  const [view, setView] = useState('absolute');  // New state for view toggle

  useEffect(() => {
    fetch('/api/vars')
      .then(response => response.json())
      .then(data => {
        setVariablesData(data);
        const groupedData = data.reduce((acc, item) => {
          if (!acc[item.category]) {
            acc[item.category] = [];
          }
          acc[item.category].push(item);
          return acc;
        }, {});
        setCategories(Object.entries(groupedData));
      })
      .catch(error => console.error('Error fetching variables:', error));
  }, []);

  useEffect(() => {
    if (variablesData.length > 0) {
      const initialVar = initialVariable
        ? variablesData.find(v => v.variable === initialVariable.variable)
        : variablesData[0];
      
      if (initialVar) {
        setSelectedVariable(initialVar);
        onSelectVariable(initialVar);
        setOpenCategory(initialVar.category);
      }
    }
  }, [variablesData, initialVariable, onSelectVariable]);

  const handleVariableSelect = useCallback((variable) => {
    setSelectedVariable(variable);
    onSelectVariable(variable);
  }, [onSelectVariable]);

  const toggleCategory = useCallback((category) => {
    setOpenCategory(prev => prev === category ? null : category);
  }, []);

  // New function to handle the toggle between absolute and percentage
  const handleViewToggle = (e) => {
    setView(e.target.value);
  };

  const filteredCategories = useMemo(() => {
    return categories.map(([category, variables]) => {
      const filteredVariables = variables.filter(variable => variable.view === view);  // Filter based on view
      return [category, filteredVariables];
    });
  }, [categories, view]);

  const categoryList = useMemo(() => filteredCategories.map(([category, variables]) => (
    <div key={category} className="border border-gray-200 rounded-lg">
      <button
        onClick={() => toggleCategory(category)}
        className="flex justify-between w-full px-4 py-2 text-sm font-medium text-left text-blue-900 bg-blue-100 rounded-lg hover:bg-blue-200 focus:outline-none focus:ring-2 focus:ring-blue-500"
      >
        <span>{category}</span>
        <svg
          className={`w-5 h-5 text-blue-500 transform ${
            openCategory === category ? 'rotate-180' : ''
          }`}
          fill="none"
          stroke="currentColor"
          viewBox="0 0 24 24"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M19 9l-7 7-7-7" />
        </svg>
      </button>
      {openCategory === category && (
        <div className="px-4 pt-4 pb-2 text-sm text-gray-500">
          <ul className="space-y-0.5">
            {variables.map((variable) => (
              <li
              key={variable.variable}
              className={`cursor-pointer p-1 rounded-md flex justify-between items-center ${
                selectedVariable && selectedVariable.variable === variable.variable
                  ? 'bg-blue-100'
                  : 'hover:bg-gray-100'
              }`}
              >
                <span 
                  className="flex-grow text-s"
                  onClick={() => handleVariableSelect(variable)}
                >
                  {variable.label}
                  </span>
                <CustomTooltip content={variable.description}>
                  <button className="ml-1 text-blue-500 hover:text-blue-700">  
                    <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4" 
                      viewBox="0 0 20 20" fill="currentColor">
                      <path fillRule="evenodd" d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7-4a1 1 0 11-2 0 1 1 0 012 0zM9 9a1 1 0 000 2v3a1 1 0 001 1h1a1 1 0 100-2v-3a1 1 0 00-1-1H9z" clipRule="evenodd" />
                    </svg>
                  </button>
                </CustomTooltip>
              </li>
            ))}
          </ul>
        </div>
      )}
    </div>
  )), [filteredCategories, openCategory, selectedVariable, handleVariableSelect, toggleCategory]);

  return (
    <div className="flex flex-col h-full p-4">
      <h3 className="text-lg font-semibold mb-4">Select Metric</h3>
      
      {/* View Toggle */}
      <div className="mb-4">
        <label className="inline-flex items-center">
          <input 
            type="radio" 
            className="form-radio" 
            name="view" 
            value="absolute" 
            checked={view === 'absolute'} 
            onChange={handleViewToggle} 
          />
          <span className="ml-2">Absolute</span>
        </label>
        <label className="inline-flex items-center ml-6">
          <input 
            type="radio" 
            className="form-radio" 
            name="view" 
            value="percentage" 
            checked={view === 'percentage'} 
            onChange={handleViewToggle} 
          />
          <span className="ml-2">Percentage</span>
        </label>
      </div>

      <div className="space-y-2 overflow-y-auto flex-grow">
        {categoryList}
      </div>
    </div>
  );
};

export default React.memo(VariableSelector);

import React from 'react';
import chroma from 'chroma-js';

const Legend = ({ lowValue, highValue, flipColors, formatting, variableLabel }) => {
  const formatValue = (value) => {
    if (value == null) return 'N/A';
    
    const absValue = Math.abs(value);
    const useThousands = absValue >= 3000;
    const roundedValue = Math.round(value);

    switch (formatting) {
      case 'dollar':
        if (useThousands) {
          const roundedDollar = Math.round(roundedValue / 1000) * 1000;
          return `$${(roundedDollar / 1000).toFixed(0)}k`;
        } else {
          return new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD', maximumFractionDigits: 0 }).format(roundedValue);
        }
      case 'percent':
        return `${Math.round(value * 100)}%`;
      case 'non_dollar':
      default:
        if (useThousands) {
          const roundedThousand = Math.round(roundedValue / 1000) * 1000;
          return `${(roundedThousand / 1000).toFixed(0)}k`;
        } else {
          return new Intl.NumberFormat('en-US', { maximumFractionDigits: 0 }).format(roundedValue);
        }
    }
  };

  const scale = flipColors ? 
    chroma.scale(['#08519c', '#f7fbff', '#ef3b2c']) :
    chroma.scale(['#ef3b2c', '#f7fbff', '#08519c']);

  const gradientColors = scale.colors(5);

  return (
    <div className="flex flex-col items-center">
      <div className="bg-blue-600 text-white px-3 py-1 rounded-full text-xs font-semibold mb-2">
        {variableLabel}
      </div>
      <div className="bg-white p-3 rounded-lg shadow" style={{ width: '240px' }}>
        <div className="flex items-center mb-2">
          <div className="w-full h-4 rounded-full" style={{
            background: `linear-gradient(to right, ${gradientColors.join(', ')})`
          }} />
        </div>
        <div className="flex justify-between text-xs">
          <span>{formatValue(lowValue)}</span>
          <span>{formatValue(highValue)}</span>
        </div>
      </div>
    </div>
  );
};

export default Legend;